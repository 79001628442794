import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { throttle } from "helpful-decorators";

type Coordinate = { x: number, y: number };

const r = 150;
const v = 8;

const background = document.getElementById('background');

const element = (tag, attrs: { [name: string]: any } = {}, children: Element[] = []) => {
    const result = document.createElementNS(background.namespaceURI, tag);

    for (const [ attr, value ] of Object.entries(attrs)) {
        result.setAttribute(attr, value);
    }

    for (const child of children) {
        result.appendChild(child);
    }

    return result;
};

const dist = (a: Coordinate, b: Coordinate) => Math.sqrt((a.x - b.x)**2 + (a.y - b.y)**2);

function *poisson(r: number, k: number = 30) : IterableIterator<Coordinate> {
    const size = r / Math.sqrt(2);

    const bound = {
        x: Math.ceil(background.clientWidth / size),
        y: Math.ceil(background.clientHeight / size)
    };

    const rand = (start: Coordinate): Coordinate => {
        const angle  = Math.random() * Math.PI * 2;
        const length = r + Math.random() * r;

        return {
            x: Math.sin(angle) * length + start.x,
            y: Math.cos(angle) * length + start.y
        }
    };

    const index = ({ x, y }: Coordinate) => Math.floor(x / background.clientWidth * bound.x) + Math.floor(y / background.clientHeight * bound.y) * bound.x;

    let grid = {
        storage: (new Array(bound.y * bound.x)).fill(null, 0, bound.x * bound.y),

        put(p: Coordinate) {
            this.storage[index(p)] = p;
        },

        possible(p: Coordinate) {
            const i = index(p);

            if (p.x < 0 || p.x > background.clientWidth || p.y < 0 || p.y > background.clientHeight) {
                return false;
            }

            const neighbours = [
                i - bound.x - 1, i - bound.x, i - bound.x + 1,
                i - 1, i, i + 1,
                i + bound.x - 1, i + bound.x, i + bound.x + 1,
            ].filter(i => i >= 0 && i < bound.x * bound.y);

            return neighbours.map(i => this.storage[i]).filter(p => p !== null).every(n => dist(n, p) > r);
        }
    };

    let result: Coordinate[] = [];
    let active: Coordinate[] = [];

    let initial = {
        x: Math.random() * background.clientWidth,
        y: Math.random() * background.clientHeight,
    };

    active.push(initial);
    grid.put(initial);

    yield initial;

    let watchdog = 0;
    while (active.length > 0) {
        const current = Math.floor(active.length * Math.random());
        const point   = active[current];

        let i = 0;
        for (; i < k; i++) {
            const candidate = rand(point);

            if (grid.possible(candidate)) {
                active.push(candidate);
                grid.put(candidate);

                yield candidate;

                break;
            }
        }

        if (i >= k) {
            active.splice(current, 1);
        }

        if (watchdog++ > 1000000) {
            break;
        }
    }

    return result;
}

type GraphNode = { p: Coordinate, v: Coordinate };

type BackgroundState = {
    points: GraphNode[],
    pairs: [Coordinate, Coordinate][]
};

type BackgroundProps = {
    points?: Coordinate[]
}

const wind = (min, current, max) => {
    switch (true) {
        case current < min:
            return max - (min - current);
        case current > max:
            return min + (max - current);
        default:
            return current;
    }
};

function pairs<T extends Coordinate>(array: T[]): [T, T][] {
    const copy   = [...array];
    const result = [];

    while (copy.length > 0) {
        const current = copy.pop();

        for (const other of copy) {
            if (dist(other, current) < 2 * r) {
                result.push([current, other])
            }
        }
    }

    return result;
}

class Background extends React.Component<BackgroundProps, BackgroundState> {
    private last = null;
    private canvas = React.createRef<HTMLCanvasElement>();

    constructor(props) {
        super(props);

        const points = props.points || Background.generatePointsRandom();

        this.state = {
            points: points.map(p => {
                const dir = 2 * Math.PI * Math.random();
                const len = 2 * v * Math.random() - v;

                return {
                    p,
                    v: {
                        x: Math.sin(dir) * len,
                        y: Math.cos(dir) * len
                    }
                }
            }),
            pairs: []
        };

        this.tick  = this.tick.bind(this);
    }

    static generatePointsRandom() {
        return (new Array(Math.ceil(background.clientWidth * background.clientHeight / (r**2)))).fill(() => ({ x: Math.random() * (background.clientWidth + 100) - 50, y: Math.random() * (background.clientHeight + 100) - 50 })).map(x => x());
    }

    componentDidMount(): void {
        requestAnimationFrame(this.tick);
    }

    componentDidUpdate(prevProps: Readonly<BackgroundProps>, prevState: Readonly<BackgroundState>, snapshot?: any): void {
        const canvas = this.canvas.current;
        const ctx    = canvas.getContext("2d");
        const [ width, height ] = [ canvas.width, canvas.height ];

        ctx.clearRect(0, 0, width, height);

        for (const [ a, b ] of this.state.pairs) {
            const d = dist(a, b);

            ctx.strokeStyle = `rgba(0, 0, 0, ${(r / d) ** 4})`;
            ctx.lineWidth   = 2 * d / r;

            ctx.beginPath();
            ctx.moveTo(a.x, a.y);
            ctx.lineTo(b.x, b.y);
            ctx.stroke();
        }

        ctx.fillStyle = "#000000";

        for (const { p } of this.state.points) {
            ctx.beginPath();
            ctx.ellipse(p.x, p.y, 3, 3, 0,Math.PI * 2,0);
            ctx.fill();
        }
    }

    @throttle(1000 / 30)
    private tick(current: DOMHighResTimeStamp) {
        this.last = this.last || current;
        const dt = (current - this.last) / 1000;
        const points = this.state.points.map(({ p, v }) => ({
            p: {
                x: wind(-50, p.x + v.x*dt, background.clientWidth + 50),
                y: wind(-50, p.y + v.y*dt, background.clientHeight + 50)
            },
            v
        }));

        this.setState({ points, pairs: pairs(points.map(({ p }) => p)) });

        this.last = current;

        requestAnimationFrame(this.tick);
    }

    render() {
        return <canvas width={background.clientWidth} height={background.clientHeight} ref={this.canvas}/>;
    }
}

ReactDOM.render(<Background />, background);
